// source: external/schedule.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.external.GetTenantStackingPlanRequest', null, global);
goog.exportSymbol('proto.external.GetTenantStackingPlanResponse', null, global);
goog.exportSymbol('proto.external.GetTenantStackingPlanResponse.Building', null, global);
goog.exportSymbol('proto.external.GetTenantsScheduleRequest', null, global);
goog.exportSymbol('proto.external.GetTenantsScheduleResponse', null, global);
goog.exportSymbol('proto.external.GetUnitStackingPlanRequest', null, global);
goog.exportSymbol('proto.external.GetUnitStackingPlanResponse', null, global);
goog.exportSymbol('proto.external.GetUnitStackingPlanResponse.Building', null, global);
goog.exportSymbol('proto.external.GetUnitsScheduleRequest', null, global);
goog.exportSymbol('proto.external.GetUnitsScheduleResponse', null, global);
goog.exportSymbol('proto.external.StackingPlan', null, global);
goog.exportSymbol('proto.external.StackingPlanByFloor', null, global);
goog.exportSymbol('proto.external.TenantSchedule', null, global);
goog.exportSymbol('proto.external.UnitSchedule', null, global);
goog.exportSymbol('proto.external.UnitStackingPlan', null, global);
goog.exportSymbol('proto.external.UnitStackingPlanByFloor', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetUnitsScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetUnitsScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetUnitsScheduleRequest.displayName = 'proto.external.GetUnitsScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetUnitStackingPlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetUnitStackingPlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetUnitStackingPlanRequest.displayName = 'proto.external.GetUnitStackingPlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetUnitStackingPlanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetUnitStackingPlanResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetUnitStackingPlanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetUnitStackingPlanResponse.displayName = 'proto.external.GetUnitStackingPlanResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetUnitStackingPlanResponse.Building = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetUnitStackingPlanResponse.Building.repeatedFields_, null);
};
goog.inherits(proto.external.GetUnitStackingPlanResponse.Building, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetUnitStackingPlanResponse.Building.displayName = 'proto.external.GetUnitStackingPlanResponse.Building';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UnitStackingPlanByFloor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.UnitStackingPlanByFloor.repeatedFields_, null);
};
goog.inherits(proto.external.UnitStackingPlanByFloor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UnitStackingPlanByFloor.displayName = 'proto.external.UnitStackingPlanByFloor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UnitStackingPlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UnitStackingPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UnitStackingPlan.displayName = 'proto.external.UnitStackingPlan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.UnitSchedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.UnitSchedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.UnitSchedule.displayName = 'proto.external.UnitSchedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetUnitsScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetUnitsScheduleResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetUnitsScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetUnitsScheduleResponse.displayName = 'proto.external.GetUnitsScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantsScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTenantsScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantsScheduleRequest.displayName = 'proto.external.GetTenantsScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantStackingPlanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetTenantStackingPlanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantStackingPlanRequest.displayName = 'proto.external.GetTenantStackingPlanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantsScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetTenantsScheduleResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetTenantsScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantsScheduleResponse.displayName = 'proto.external.GetTenantsScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.TenantSchedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.TenantSchedule.repeatedFields_, null);
};
goog.inherits(proto.external.TenantSchedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.TenantSchedule.displayName = 'proto.external.TenantSchedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantStackingPlanResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetTenantStackingPlanResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetTenantStackingPlanResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantStackingPlanResponse.displayName = 'proto.external.GetTenantStackingPlanResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetTenantStackingPlanResponse.Building = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetTenantStackingPlanResponse.Building.repeatedFields_, null);
};
goog.inherits(proto.external.GetTenantStackingPlanResponse.Building, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetTenantStackingPlanResponse.Building.displayName = 'proto.external.GetTenantStackingPlanResponse.Building';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.StackingPlanByFloor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.StackingPlanByFloor.repeatedFields_, null);
};
goog.inherits(proto.external.StackingPlanByFloor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.StackingPlanByFloor.displayName = 'proto.external.StackingPlanByFloor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.StackingPlan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.StackingPlan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.StackingPlan.displayName = 'proto.external.StackingPlan';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetUnitsScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetUnitsScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetUnitsScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetUnitsScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetUnitsScheduleRequest}
 */
proto.external.GetUnitsScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetUnitsScheduleRequest;
  return proto.external.GetUnitsScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetUnitsScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetUnitsScheduleRequest}
 */
proto.external.GetUnitsScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetUnitsScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetUnitsScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetUnitsScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetUnitsScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetUnitsScheduleRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetUnitsScheduleRequest} returns this
 */
proto.external.GetUnitsScheduleRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetUnitStackingPlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetUnitStackingPlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetUnitStackingPlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetUnitStackingPlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetUnitStackingPlanRequest}
 */
proto.external.GetUnitStackingPlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetUnitStackingPlanRequest;
  return proto.external.GetUnitStackingPlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetUnitStackingPlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetUnitStackingPlanRequest}
 */
proto.external.GetUnitStackingPlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetUnitStackingPlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetUnitStackingPlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetUnitStackingPlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetUnitStackingPlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetUnitStackingPlanRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetUnitStackingPlanRequest} returns this
 */
proto.external.GetUnitStackingPlanRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetUnitStackingPlanResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetUnitStackingPlanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetUnitStackingPlanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetUnitStackingPlanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetUnitStackingPlanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    buildingsList: jspb.Message.toObjectList(msg.getBuildingsList(),
    proto.external.GetUnitStackingPlanResponse.Building.toObject, includeInstance),
    hasbuildings: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetUnitStackingPlanResponse}
 */
proto.external.GetUnitStackingPlanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetUnitStackingPlanResponse;
  return proto.external.GetUnitStackingPlanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetUnitStackingPlanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetUnitStackingPlanResponse}
 */
proto.external.GetUnitStackingPlanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.GetUnitStackingPlanResponse.Building;
      reader.readMessage(value,proto.external.GetUnitStackingPlanResponse.Building.deserializeBinaryFromReader);
      msg.addBuildings(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasbuildings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetUnitStackingPlanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetUnitStackingPlanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetUnitStackingPlanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetUnitStackingPlanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBuildingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.GetUnitStackingPlanResponse.Building.serializeBinaryToWriter
    );
  }
  f = message.getHasbuildings();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetUnitStackingPlanResponse.Building.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetUnitStackingPlanResponse.Building.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetUnitStackingPlanResponse.Building.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetUnitStackingPlanResponse.Building} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetUnitStackingPlanResponse.Building.toObject = function(includeInstance, msg) {
  var f, obj = {
    buildingname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stackingplanbyfloorList: jspb.Message.toObjectList(msg.getStackingplanbyfloorList(),
    proto.external.UnitStackingPlanByFloor.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetUnitStackingPlanResponse.Building}
 */
proto.external.GetUnitStackingPlanResponse.Building.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetUnitStackingPlanResponse.Building;
  return proto.external.GetUnitStackingPlanResponse.Building.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetUnitStackingPlanResponse.Building} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetUnitStackingPlanResponse.Building}
 */
proto.external.GetUnitStackingPlanResponse.Building.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuildingname(value);
      break;
    case 2:
      var value = new proto.external.UnitStackingPlanByFloor;
      reader.readMessage(value,proto.external.UnitStackingPlanByFloor.deserializeBinaryFromReader);
      msg.addStackingplanbyfloor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetUnitStackingPlanResponse.Building.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetUnitStackingPlanResponse.Building.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetUnitStackingPlanResponse.Building} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetUnitStackingPlanResponse.Building.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBuildingname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStackingplanbyfloorList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.external.UnitStackingPlanByFloor.serializeBinaryToWriter
    );
  }
};


/**
 * optional string buildingName = 1;
 * @return {string}
 */
proto.external.GetUnitStackingPlanResponse.Building.prototype.getBuildingname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetUnitStackingPlanResponse.Building} returns this
 */
proto.external.GetUnitStackingPlanResponse.Building.prototype.setBuildingname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated UnitStackingPlanByFloor stackingPlanByFloor = 2;
 * @return {!Array<!proto.external.UnitStackingPlanByFloor>}
 */
proto.external.GetUnitStackingPlanResponse.Building.prototype.getStackingplanbyfloorList = function() {
  return /** @type{!Array<!proto.external.UnitStackingPlanByFloor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.UnitStackingPlanByFloor, 2));
};


/**
 * @param {!Array<!proto.external.UnitStackingPlanByFloor>} value
 * @return {!proto.external.GetUnitStackingPlanResponse.Building} returns this
*/
proto.external.GetUnitStackingPlanResponse.Building.prototype.setStackingplanbyfloorList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.external.UnitStackingPlanByFloor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.UnitStackingPlanByFloor}
 */
proto.external.GetUnitStackingPlanResponse.Building.prototype.addStackingplanbyfloor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.external.UnitStackingPlanByFloor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetUnitStackingPlanResponse.Building} returns this
 */
proto.external.GetUnitStackingPlanResponse.Building.prototype.clearStackingplanbyfloorList = function() {
  return this.setStackingplanbyfloorList([]);
};


/**
 * repeated Building buildings = 1;
 * @return {!Array<!proto.external.GetUnitStackingPlanResponse.Building>}
 */
proto.external.GetUnitStackingPlanResponse.prototype.getBuildingsList = function() {
  return /** @type{!Array<!proto.external.GetUnitStackingPlanResponse.Building>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.GetUnitStackingPlanResponse.Building, 1));
};


/**
 * @param {!Array<!proto.external.GetUnitStackingPlanResponse.Building>} value
 * @return {!proto.external.GetUnitStackingPlanResponse} returns this
*/
proto.external.GetUnitStackingPlanResponse.prototype.setBuildingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.GetUnitStackingPlanResponse.Building=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.GetUnitStackingPlanResponse.Building}
 */
proto.external.GetUnitStackingPlanResponse.prototype.addBuildings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.GetUnitStackingPlanResponse.Building, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetUnitStackingPlanResponse} returns this
 */
proto.external.GetUnitStackingPlanResponse.prototype.clearBuildingsList = function() {
  return this.setBuildingsList([]);
};


/**
 * optional bool hasBuildings = 2;
 * @return {boolean}
 */
proto.external.GetUnitStackingPlanResponse.prototype.getHasbuildings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.GetUnitStackingPlanResponse} returns this
 */
proto.external.GetUnitStackingPlanResponse.prototype.setHasbuildings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.UnitStackingPlanByFloor.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.UnitStackingPlanByFloor.prototype.toObject = function(opt_includeInstance) {
  return proto.external.UnitStackingPlanByFloor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.UnitStackingPlanByFloor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UnitStackingPlanByFloor.toObject = function(includeInstance, msg) {
  var f, obj = {
    floor: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalgla: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    stackingplanList: jspb.Message.toObjectList(msg.getStackingplanList(),
    proto.external.UnitStackingPlan.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UnitStackingPlanByFloor}
 */
proto.external.UnitStackingPlanByFloor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UnitStackingPlanByFloor;
  return proto.external.UnitStackingPlanByFloor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UnitStackingPlanByFloor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UnitStackingPlanByFloor}
 */
proto.external.UnitStackingPlanByFloor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFloor(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalgla(value);
      break;
    case 3:
      var value = new proto.external.UnitStackingPlan;
      reader.readMessage(value,proto.external.UnitStackingPlan.deserializeBinaryFromReader);
      msg.addStackingplan(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UnitStackingPlanByFloor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UnitStackingPlanByFloor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UnitStackingPlanByFloor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UnitStackingPlanByFloor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFloor();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotalgla();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getStackingplanList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.external.UnitStackingPlan.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 floor = 1;
 * @return {number}
 */
proto.external.UnitStackingPlanByFloor.prototype.getFloor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.UnitStackingPlanByFloor} returns this
 */
proto.external.UnitStackingPlanByFloor.prototype.setFloor = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double totalGLA = 2;
 * @return {number}
 */
proto.external.UnitStackingPlanByFloor.prototype.getTotalgla = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.UnitStackingPlanByFloor} returns this
 */
proto.external.UnitStackingPlanByFloor.prototype.setTotalgla = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated UnitStackingPlan stackingPlan = 3;
 * @return {!Array<!proto.external.UnitStackingPlan>}
 */
proto.external.UnitStackingPlanByFloor.prototype.getStackingplanList = function() {
  return /** @type{!Array<!proto.external.UnitStackingPlan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.UnitStackingPlan, 3));
};


/**
 * @param {!Array<!proto.external.UnitStackingPlan>} value
 * @return {!proto.external.UnitStackingPlanByFloor} returns this
*/
proto.external.UnitStackingPlanByFloor.prototype.setStackingplanList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.external.UnitStackingPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.UnitStackingPlan}
 */
proto.external.UnitStackingPlanByFloor.prototype.addStackingplan = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.external.UnitStackingPlan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.UnitStackingPlanByFloor} returns this
 */
proto.external.UnitStackingPlanByFloor.prototype.clearStackingplanList = function() {
  return this.setStackingplanList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.UnitStackingPlan.prototype.toObject = function(opt_includeInstance) {
  return proto.external.UnitStackingPlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.UnitStackingPlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UnitStackingPlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    unitid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    unitname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tenantname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    gla: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    startdate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    tenantid: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UnitStackingPlan}
 */
proto.external.UnitStackingPlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UnitStackingPlan;
  return proto.external.UnitStackingPlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UnitStackingPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UnitStackingPlan}
 */
proto.external.UnitStackingPlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGla(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UnitStackingPlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UnitStackingPlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UnitStackingPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UnitStackingPlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnitid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUnitname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGla();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string unitID = 1;
 * @return {string}
 */
proto.external.UnitStackingPlan.prototype.getUnitid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.UnitStackingPlan} returns this
 */
proto.external.UnitStackingPlan.prototype.setUnitid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string unitName = 2;
 * @return {string}
 */
proto.external.UnitStackingPlan.prototype.getUnitname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.UnitStackingPlan} returns this
 */
proto.external.UnitStackingPlan.prototype.setUnitname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tenantName = 3;
 * @return {string}
 */
proto.external.UnitStackingPlan.prototype.getTenantname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.UnitStackingPlan} returns this
 */
proto.external.UnitStackingPlan.prototype.setTenantname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string endDate = 4;
 * @return {string}
 */
proto.external.UnitStackingPlan.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.UnitStackingPlan} returns this
 */
proto.external.UnitStackingPlan.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double gla = 5;
 * @return {number}
 */
proto.external.UnitStackingPlan.prototype.getGla = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.UnitStackingPlan} returns this
 */
proto.external.UnitStackingPlan.prototype.setGla = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string startDate = 6;
 * @return {string}
 */
proto.external.UnitStackingPlan.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.UnitStackingPlan} returns this
 */
proto.external.UnitStackingPlan.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string tenantID = 7;
 * @return {string}
 */
proto.external.UnitStackingPlan.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.UnitStackingPlan} returns this
 */
proto.external.UnitStackingPlan.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.UnitSchedule.prototype.toObject = function(opt_includeInstance) {
  return proto.external.UnitSchedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.UnitSchedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UnitSchedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    unitid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    unitname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tenantname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tenantid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    floor: jspb.Message.getFieldWithDefault(msg, 5, 0),
    gla: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    buildingname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    powerSupplyNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    waterSupplyNumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    autonomousBuilding: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    establishmentOfHorizontalProperty: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    pea: jspb.Message.getFieldWithDefault(msg, 12, ""),
    expirationOfPea: jspb.Message.getFieldWithDefault(msg, 13, ""),
    kaek: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.UnitSchedule}
 */
proto.external.UnitSchedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.UnitSchedule;
  return proto.external.UnitSchedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.UnitSchedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.UnitSchedule}
 */
proto.external.UnitSchedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFloor(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGla(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuildingname(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPowerSupplyNumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setWaterSupplyNumber(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutonomousBuilding(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEstablishmentOfHorizontalProperty(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPea(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpirationOfPea(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setKaek(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.UnitSchedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.UnitSchedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.UnitSchedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.UnitSchedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnitid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUnitname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFloor();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getGla();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getBuildingname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPowerSupplyNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getWaterSupplyNumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAutonomousBuilding();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getEstablishmentOfHorizontalProperty();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getPea();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getExpirationOfPea();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getKaek();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string unitID = 1;
 * @return {string}
 */
proto.external.UnitSchedule.prototype.getUnitid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.UnitSchedule} returns this
 */
proto.external.UnitSchedule.prototype.setUnitid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string unitName = 2;
 * @return {string}
 */
proto.external.UnitSchedule.prototype.getUnitname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.UnitSchedule} returns this
 */
proto.external.UnitSchedule.prototype.setUnitname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tenantName = 3;
 * @return {string}
 */
proto.external.UnitSchedule.prototype.getTenantname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.UnitSchedule} returns this
 */
proto.external.UnitSchedule.prototype.setTenantname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tenantID = 4;
 * @return {string}
 */
proto.external.UnitSchedule.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.UnitSchedule} returns this
 */
proto.external.UnitSchedule.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 floor = 5;
 * @return {number}
 */
proto.external.UnitSchedule.prototype.getFloor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.UnitSchedule} returns this
 */
proto.external.UnitSchedule.prototype.setFloor = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional double gla = 6;
 * @return {number}
 */
proto.external.UnitSchedule.prototype.getGla = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.UnitSchedule} returns this
 */
proto.external.UnitSchedule.prototype.setGla = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string buildingName = 7;
 * @return {string}
 */
proto.external.UnitSchedule.prototype.getBuildingname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.UnitSchedule} returns this
 */
proto.external.UnitSchedule.prototype.setBuildingname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string power_supply_number = 8;
 * @return {string}
 */
proto.external.UnitSchedule.prototype.getPowerSupplyNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.UnitSchedule} returns this
 */
proto.external.UnitSchedule.prototype.setPowerSupplyNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string water_supply_number = 9;
 * @return {string}
 */
proto.external.UnitSchedule.prototype.getWaterSupplyNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.UnitSchedule} returns this
 */
proto.external.UnitSchedule.prototype.setWaterSupplyNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool autonomous_building = 10;
 * @return {boolean}
 */
proto.external.UnitSchedule.prototype.getAutonomousBuilding = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.UnitSchedule} returns this
 */
proto.external.UnitSchedule.prototype.setAutonomousBuilding = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool establishment_of_horizontal_property = 11;
 * @return {boolean}
 */
proto.external.UnitSchedule.prototype.getEstablishmentOfHorizontalProperty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.UnitSchedule} returns this
 */
proto.external.UnitSchedule.prototype.setEstablishmentOfHorizontalProperty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string PEA = 12;
 * @return {string}
 */
proto.external.UnitSchedule.prototype.getPea = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.UnitSchedule} returns this
 */
proto.external.UnitSchedule.prototype.setPea = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string expiration_of_pea = 13;
 * @return {string}
 */
proto.external.UnitSchedule.prototype.getExpirationOfPea = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.UnitSchedule} returns this
 */
proto.external.UnitSchedule.prototype.setExpirationOfPea = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string kaek = 14;
 * @return {string}
 */
proto.external.UnitSchedule.prototype.getKaek = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.UnitSchedule} returns this
 */
proto.external.UnitSchedule.prototype.setKaek = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetUnitsScheduleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetUnitsScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetUnitsScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetUnitsScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetUnitsScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unitsList: jspb.Message.toObjectList(msg.getUnitsList(),
    proto.external.UnitSchedule.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetUnitsScheduleResponse}
 */
proto.external.GetUnitsScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetUnitsScheduleResponse;
  return proto.external.GetUnitsScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetUnitsScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetUnitsScheduleResponse}
 */
proto.external.GetUnitsScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.UnitSchedule;
      reader.readMessage(value,proto.external.UnitSchedule.deserializeBinaryFromReader);
      msg.addUnits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetUnitsScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetUnitsScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetUnitsScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetUnitsScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.UnitSchedule.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UnitSchedule units = 1;
 * @return {!Array<!proto.external.UnitSchedule>}
 */
proto.external.GetUnitsScheduleResponse.prototype.getUnitsList = function() {
  return /** @type{!Array<!proto.external.UnitSchedule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.UnitSchedule, 1));
};


/**
 * @param {!Array<!proto.external.UnitSchedule>} value
 * @return {!proto.external.GetUnitsScheduleResponse} returns this
*/
proto.external.GetUnitsScheduleResponse.prototype.setUnitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.UnitSchedule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.UnitSchedule}
 */
proto.external.GetUnitsScheduleResponse.prototype.addUnits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.UnitSchedule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetUnitsScheduleResponse} returns this
 */
proto.external.GetUnitsScheduleResponse.prototype.clearUnitsList = function() {
  return this.setUnitsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetTenantsScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetTenantsScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetTenantsScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantsScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantsScheduleRequest}
 */
proto.external.GetTenantsScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantsScheduleRequest;
  return proto.external.GetTenantsScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantsScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantsScheduleRequest}
 */
proto.external.GetTenantsScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantsScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantsScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantsScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantsScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetTenantsScheduleRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantsScheduleRequest} returns this
 */
proto.external.GetTenantsScheduleRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetTenantStackingPlanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetTenantStackingPlanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetTenantStackingPlanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantStackingPlanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantStackingPlanRequest}
 */
proto.external.GetTenantStackingPlanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantStackingPlanRequest;
  return proto.external.GetTenantStackingPlanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantStackingPlanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantStackingPlanRequest}
 */
proto.external.GetTenantStackingPlanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantStackingPlanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantStackingPlanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantStackingPlanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantStackingPlanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string assetID = 1;
 * @return {string}
 */
proto.external.GetTenantStackingPlanRequest.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantStackingPlanRequest} returns this
 */
proto.external.GetTenantStackingPlanRequest.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetTenantsScheduleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetTenantsScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetTenantsScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetTenantsScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantsScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    unitsList: jspb.Message.toObjectList(msg.getUnitsList(),
    proto.external.TenantSchedule.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantsScheduleResponse}
 */
proto.external.GetTenantsScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantsScheduleResponse;
  return proto.external.GetTenantsScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantsScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantsScheduleResponse}
 */
proto.external.GetTenantsScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.TenantSchedule;
      reader.readMessage(value,proto.external.TenantSchedule.deserializeBinaryFromReader);
      msg.addUnits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantsScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantsScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantsScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantsScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.TenantSchedule.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TenantSchedule units = 1;
 * @return {!Array<!proto.external.TenantSchedule>}
 */
proto.external.GetTenantsScheduleResponse.prototype.getUnitsList = function() {
  return /** @type{!Array<!proto.external.TenantSchedule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.TenantSchedule, 1));
};


/**
 * @param {!Array<!proto.external.TenantSchedule>} value
 * @return {!proto.external.GetTenantsScheduleResponse} returns this
*/
proto.external.GetTenantsScheduleResponse.prototype.setUnitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.TenantSchedule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.TenantSchedule}
 */
proto.external.GetTenantsScheduleResponse.prototype.addUnits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.TenantSchedule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTenantsScheduleResponse} returns this
 */
proto.external.GetTenantsScheduleResponse.prototype.clearUnitsList = function() {
  return this.setUnitsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.TenantSchedule.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.TenantSchedule.prototype.toObject = function(opt_includeInstance) {
  return proto.external.TenantSchedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.TenantSchedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantSchedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    unitnamesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    startdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    annualrent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    gla: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    warning: jspb.Message.getFieldWithDefault(msg, 8, ""),
    tradingname: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.TenantSchedule}
 */
proto.external.TenantSchedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.TenantSchedule;
  return proto.external.TenantSchedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.TenantSchedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.TenantSchedule}
 */
proto.external.TenantSchedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addUnitnames(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAnnualrent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGla(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setWarning(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTradingname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.TenantSchedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.TenantSchedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.TenantSchedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.TenantSchedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUnitnamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAnnualrent();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getGla();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getWarning();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTradingname();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string tenantName = 1;
 * @return {string}
 */
proto.external.TenantSchedule.prototype.getTenantname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantSchedule} returns this
 */
proto.external.TenantSchedule.prototype.setTenantname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenantID = 2;
 * @return {string}
 */
proto.external.TenantSchedule.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantSchedule} returns this
 */
proto.external.TenantSchedule.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string unitNames = 3;
 * @return {!Array<string>}
 */
proto.external.TenantSchedule.prototype.getUnitnamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.external.TenantSchedule} returns this
 */
proto.external.TenantSchedule.prototype.setUnitnamesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.external.TenantSchedule} returns this
 */
proto.external.TenantSchedule.prototype.addUnitnames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.TenantSchedule} returns this
 */
proto.external.TenantSchedule.prototype.clearUnitnamesList = function() {
  return this.setUnitnamesList([]);
};


/**
 * optional string startDate = 4;
 * @return {string}
 */
proto.external.TenantSchedule.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantSchedule} returns this
 */
proto.external.TenantSchedule.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string endDate = 5;
 * @return {string}
 */
proto.external.TenantSchedule.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantSchedule} returns this
 */
proto.external.TenantSchedule.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double annualRent = 6;
 * @return {number}
 */
proto.external.TenantSchedule.prototype.getAnnualrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TenantSchedule} returns this
 */
proto.external.TenantSchedule.prototype.setAnnualrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double gla = 7;
 * @return {number}
 */
proto.external.TenantSchedule.prototype.getGla = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.TenantSchedule} returns this
 */
proto.external.TenantSchedule.prototype.setGla = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string warning = 8;
 * @return {string}
 */
proto.external.TenantSchedule.prototype.getWarning = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantSchedule} returns this
 */
proto.external.TenantSchedule.prototype.setWarning = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string tradingName = 9;
 * @return {string}
 */
proto.external.TenantSchedule.prototype.getTradingname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.TenantSchedule} returns this
 */
proto.external.TenantSchedule.prototype.setTradingname = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetTenantStackingPlanResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetTenantStackingPlanResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetTenantStackingPlanResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetTenantStackingPlanResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantStackingPlanResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    buildingsList: jspb.Message.toObjectList(msg.getBuildingsList(),
    proto.external.GetTenantStackingPlanResponse.Building.toObject, includeInstance),
    hasbuildings: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantStackingPlanResponse}
 */
proto.external.GetTenantStackingPlanResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantStackingPlanResponse;
  return proto.external.GetTenantStackingPlanResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantStackingPlanResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantStackingPlanResponse}
 */
proto.external.GetTenantStackingPlanResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.GetTenantStackingPlanResponse.Building;
      reader.readMessage(value,proto.external.GetTenantStackingPlanResponse.Building.deserializeBinaryFromReader);
      msg.addBuildings(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasbuildings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantStackingPlanResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantStackingPlanResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantStackingPlanResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantStackingPlanResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBuildingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.GetTenantStackingPlanResponse.Building.serializeBinaryToWriter
    );
  }
  f = message.getHasbuildings();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetTenantStackingPlanResponse.Building.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetTenantStackingPlanResponse.Building.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetTenantStackingPlanResponse.Building.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetTenantStackingPlanResponse.Building} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantStackingPlanResponse.Building.toObject = function(includeInstance, msg) {
  var f, obj = {
    buildingname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stackingplanbyfloorList: jspb.Message.toObjectList(msg.getStackingplanbyfloorList(),
    proto.external.StackingPlanByFloor.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetTenantStackingPlanResponse.Building}
 */
proto.external.GetTenantStackingPlanResponse.Building.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetTenantStackingPlanResponse.Building;
  return proto.external.GetTenantStackingPlanResponse.Building.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetTenantStackingPlanResponse.Building} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetTenantStackingPlanResponse.Building}
 */
proto.external.GetTenantStackingPlanResponse.Building.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuildingname(value);
      break;
    case 2:
      var value = new proto.external.StackingPlanByFloor;
      reader.readMessage(value,proto.external.StackingPlanByFloor.deserializeBinaryFromReader);
      msg.addStackingplanbyfloor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetTenantStackingPlanResponse.Building.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetTenantStackingPlanResponse.Building.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetTenantStackingPlanResponse.Building} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetTenantStackingPlanResponse.Building.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBuildingname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStackingplanbyfloorList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.external.StackingPlanByFloor.serializeBinaryToWriter
    );
  }
};


/**
 * optional string buildingName = 1;
 * @return {string}
 */
proto.external.GetTenantStackingPlanResponse.Building.prototype.getBuildingname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.GetTenantStackingPlanResponse.Building} returns this
 */
proto.external.GetTenantStackingPlanResponse.Building.prototype.setBuildingname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated StackingPlanByFloor stackingPlanByFloor = 2;
 * @return {!Array<!proto.external.StackingPlanByFloor>}
 */
proto.external.GetTenantStackingPlanResponse.Building.prototype.getStackingplanbyfloorList = function() {
  return /** @type{!Array<!proto.external.StackingPlanByFloor>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.StackingPlanByFloor, 2));
};


/**
 * @param {!Array<!proto.external.StackingPlanByFloor>} value
 * @return {!proto.external.GetTenantStackingPlanResponse.Building} returns this
*/
proto.external.GetTenantStackingPlanResponse.Building.prototype.setStackingplanbyfloorList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.external.StackingPlanByFloor=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.StackingPlanByFloor}
 */
proto.external.GetTenantStackingPlanResponse.Building.prototype.addStackingplanbyfloor = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.external.StackingPlanByFloor, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTenantStackingPlanResponse.Building} returns this
 */
proto.external.GetTenantStackingPlanResponse.Building.prototype.clearStackingplanbyfloorList = function() {
  return this.setStackingplanbyfloorList([]);
};


/**
 * repeated Building buildings = 1;
 * @return {!Array<!proto.external.GetTenantStackingPlanResponse.Building>}
 */
proto.external.GetTenantStackingPlanResponse.prototype.getBuildingsList = function() {
  return /** @type{!Array<!proto.external.GetTenantStackingPlanResponse.Building>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.GetTenantStackingPlanResponse.Building, 1));
};


/**
 * @param {!Array<!proto.external.GetTenantStackingPlanResponse.Building>} value
 * @return {!proto.external.GetTenantStackingPlanResponse} returns this
*/
proto.external.GetTenantStackingPlanResponse.prototype.setBuildingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.GetTenantStackingPlanResponse.Building=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.GetTenantStackingPlanResponse.Building}
 */
proto.external.GetTenantStackingPlanResponse.prototype.addBuildings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.GetTenantStackingPlanResponse.Building, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetTenantStackingPlanResponse} returns this
 */
proto.external.GetTenantStackingPlanResponse.prototype.clearBuildingsList = function() {
  return this.setBuildingsList([]);
};


/**
 * optional bool hasBuildings = 2;
 * @return {boolean}
 */
proto.external.GetTenantStackingPlanResponse.prototype.getHasbuildings = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.GetTenantStackingPlanResponse} returns this
 */
proto.external.GetTenantStackingPlanResponse.prototype.setHasbuildings = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.StackingPlanByFloor.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.StackingPlanByFloor.prototype.toObject = function(opt_includeInstance) {
  return proto.external.StackingPlanByFloor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.StackingPlanByFloor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.StackingPlanByFloor.toObject = function(includeInstance, msg) {
  var f, obj = {
    floor: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalgla: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    stackingplanList: jspb.Message.toObjectList(msg.getStackingplanList(),
    proto.external.StackingPlan.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.StackingPlanByFloor}
 */
proto.external.StackingPlanByFloor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.StackingPlanByFloor;
  return proto.external.StackingPlanByFloor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.StackingPlanByFloor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.StackingPlanByFloor}
 */
proto.external.StackingPlanByFloor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFloor(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalgla(value);
      break;
    case 3:
      var value = new proto.external.StackingPlan;
      reader.readMessage(value,proto.external.StackingPlan.deserializeBinaryFromReader);
      msg.addStackingplan(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.StackingPlanByFloor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.StackingPlanByFloor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.StackingPlanByFloor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.StackingPlanByFloor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFloor();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotalgla();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getStackingplanList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.external.StackingPlan.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 floor = 1;
 * @return {number}
 */
proto.external.StackingPlanByFloor.prototype.getFloor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.StackingPlanByFloor} returns this
 */
proto.external.StackingPlanByFloor.prototype.setFloor = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double totalGLA = 2;
 * @return {number}
 */
proto.external.StackingPlanByFloor.prototype.getTotalgla = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.StackingPlanByFloor} returns this
 */
proto.external.StackingPlanByFloor.prototype.setTotalgla = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * repeated StackingPlan stackingPlan = 3;
 * @return {!Array<!proto.external.StackingPlan>}
 */
proto.external.StackingPlanByFloor.prototype.getStackingplanList = function() {
  return /** @type{!Array<!proto.external.StackingPlan>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.StackingPlan, 3));
};


/**
 * @param {!Array<!proto.external.StackingPlan>} value
 * @return {!proto.external.StackingPlanByFloor} returns this
*/
proto.external.StackingPlanByFloor.prototype.setStackingplanList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.external.StackingPlan=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.StackingPlan}
 */
proto.external.StackingPlanByFloor.prototype.addStackingplan = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.external.StackingPlan, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.StackingPlanByFloor} returns this
 */
proto.external.StackingPlanByFloor.prototype.clearStackingplanList = function() {
  return this.setStackingplanList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.StackingPlan.prototype.toObject = function(opt_includeInstance) {
  return proto.external.StackingPlan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.StackingPlan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.StackingPlan.toObject = function(includeInstance, msg) {
  var f, obj = {
    tenantname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tenantid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    unitname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    enddate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    gla: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.StackingPlan}
 */
proto.external.StackingPlan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.StackingPlan;
  return proto.external.StackingPlan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.StackingPlan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.StackingPlan}
 */
proto.external.StackingPlan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartdate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnddate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGla(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.StackingPlan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.StackingPlan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.StackingPlan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.StackingPlan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTenantname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUnitname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartdate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnddate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGla();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional string tenantName = 1;
 * @return {string}
 */
proto.external.StackingPlan.prototype.getTenantname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.StackingPlan} returns this
 */
proto.external.StackingPlan.prototype.setTenantname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tenantID = 2;
 * @return {string}
 */
proto.external.StackingPlan.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.StackingPlan} returns this
 */
proto.external.StackingPlan.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string unitName = 3;
 * @return {string}
 */
proto.external.StackingPlan.prototype.getUnitname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.StackingPlan} returns this
 */
proto.external.StackingPlan.prototype.setUnitname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string startDate = 4;
 * @return {string}
 */
proto.external.StackingPlan.prototype.getStartdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.StackingPlan} returns this
 */
proto.external.StackingPlan.prototype.setStartdate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string endDate = 5;
 * @return {string}
 */
proto.external.StackingPlan.prototype.getEnddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.StackingPlan} returns this
 */
proto.external.StackingPlan.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double gla = 6;
 * @return {number}
 */
proto.external.StackingPlan.prototype.getGla = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.StackingPlan} returns this
 */
proto.external.StackingPlan.prototype.setGla = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


goog.object.extend(exports, proto.external);
